import React, { useState } from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Grid, Icon, Label, Popup } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeh, faUserTimes, faUserCheck, faPhone, faExternalLinkAlt, faPlus } from "@fortawesome/free-solid-svg-icons";

import MechanicNote from "./MechanicNote";
import AppointmentTyres from "./AppointmentTyres";
import AppointmentTruckTyres from "./AppointmentTruckTyres";
import AddAppointmentIntervention from "./AddAppointmentIntervention";
import Can from "../Can";
import { getPriceForDisplay } from "./util";
import { renderPinTypeIcon } from "../../components/Pin/util";
import { renderBasedOnTemplateAndOptions, getFilesButton, pinLogAttachmentList, ANSWER_STATUSES } from "../../util/common";
import { AnswerStatusSelector, ThumbToggle, Snooze, Pin, InlineInputIcon, FileDropDown } from "../../components";
import { DMS } from "../DMSv3/util";
import { CHECKLIST_TYPE } from "../Checklists/enum";
import {
  COMMUNICATION_STATUSES,
  COMMUNICATION_RESULT_STATUSES,
  QUESTION_RESULT_STATUS,
  TYRE_POSITION,
  renderMedia,
  renderReadyStatus,
  getTyreSeasonIcon,
} from "./common";

import Service from "./service";

const AppointmentQuestionResult = ({
  t,
  vat,
  includeVAT,
  questions,
  getTotalPrice,
  tyreMediaVisible,
  template,
  dashboardConfig,
  checklist: initialChecklist,
  onMediaToggle,
  onTyreMediaToggle,
  onMediaClick,
  onAnswerUpdate,
  isFinalCheckDone,
  displayFrontIcon,
  isEditable,
  isPriceEditable,
  readOnly,
  readOnlyRemarks,
  isCustomerCommunicationVisible,
  selectedCustomerComm,
  onChangeCustomerCommunication,
  appointment,
  onAppointmentClick,
  onPriceChange,
  onTyreReplacementAnswerUpdate,
  onTyreReplaceDelete,
  onToggleHistory,
  onToggleHistoryItem,
  isImportantItem,
  isAdvisedCriticalItemsSection,
  historyDisplayed,
  location,
  isLoading,
  keyloopEnabled,
}) => {
  const [convertToIntervention, setConvertToIntervention] = useState(null);
  const [isConvertingToIntervention, setIsConvertingToIntervention] = useState(false);
  const [convertToInterventionError, setConvertToInterventionError] = useState("");

  const getActiveImages = images => {
    if (!images || images.length === 0) {
      return [];
    }

    return images.filter(i => i.active);
  };

  const getActiveVideos = videos => {
    if (!videos || videos.length === 0) {
      return [];
    }

    return videos.filter(i => i.active);
  };

  const getCustomerCommIconWithColor = status => {
    let customerComm = { icon: "", color: "" };
    switch (status) {
      case 1:
        customerComm.icon = faMeh;
        customerComm.color = "grey";
        break;
      case 2:
        customerComm.icon = faUserCheck;
        customerComm.color = "green";
        break;
      case 3:
        customerComm.icon = faUserTimes;
        customerComm.color = "red";
        break;
      case 4:
        customerComm.icon = faPhone;
        customerComm.color = "blue";
        break;
      default:
        return false;
    }
    return customerComm;
  };

  const checkCustomerComm = (question_status, diagnose_overview_results, id, trid) => {
    if (Array.isArray(diagnose_overview_results)) {
      const comres = diagnose_overview_results.find(c => c.question_result_id === id);
      if (comres) {
        let status = comres.status;
        if ([COMMUNICATION_RESULT_STATUSES.OK, COMMUNICATION_RESULT_STATUSES.CONTACT_ME].includes(status) && trid && Array.isArray(comres.tyre_replacements)) {
          let rep = comres.tyre_replacements.find(tr => tr.tyre_replacement_id === trid);
          if (rep && !rep.selected) {
            return null;
          }
        }

        if ([QUESTION_RESULT_STATUS.OK, QUESTION_RESULT_STATUS.OK_WITH_REMARKS].includes(question_status) && status === COMMUNICATION_RESULT_STATUSES.NOT_ANSWERED)
          return null;

        const customer = getCustomerCommIconWithColor(status);
        if (customer) {
          return (
            <Label className="customer-comm-label" color={customer.color}>
              <FontAwesomeIcon icon={customer.icon} color="white" className="icon" />
            </Label>
          );
        }
      }
    }
  };

  const renderHeaders = () => {
    if (!isAdvisedCriticalItemsSection) return;

    return (
      <Grid style={{ paddingTop: "0", paddingBottom: "0" }} className="AdvisedCriticalItems__headers">
        <Grid.Row>
          <Grid.Column
            width={3}
            className="AppointmentChecklistGroup__header AppointmentChecklistGroup__header__appointmentInfo"
            style={{ display: "flex", alignItems: "center" }}
          >
            <span>{t("Appointment_date").message || "Appointment Date"}</span>
            <span>{t("WO Nr").message || "WO #"}</span>
          </Grid.Column>
          <Grid.Column width={includeVAT ? 2 : 3} className="AppointmentChecklistGroup__header">
            <span>{t("item").message || "Item"}</span>
          </Grid.Column>
          <Grid.Column widescreen={includeVAT ? 4 : 5} computer={includeVAT ? 3 : 4} className="AppointmentChecklistGroup__header">
            <span>{t("remarks").message || "Remarks"}</span>
          </Grid.Column>
          <Grid.Column width={2} className="AppointmentChecklistGroup__header">
            <span>{t("no_vat_price").message || "Excl. VAT"}</span>
          </Grid.Column>

          {includeVAT && (
            <Grid.Column width={2} className="AppointmentChecklistGroup__header">
              <span>{t("vat_price").message || "Incl. VAT"}</span>
            </Grid.Column>
          )}

          <Grid.Column computer={4} widescreen={3} textAlign="right" className="AppointmentChecklistGroup__header">
            <div className="history-icon-container" onClick={() => onToggleHistory()} style={{ display: "inline-block" }}>
              <Popup
                content={t("show_hidden_items_icon").message || "This icon is to show hidden items"}
                position="top right"
                trigger={
                  <Label className="history-label" color={historyDisplayed ? "blue" : null}>
                    <Icon name="history" />
                  </Label>
                }
              />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const handleConvertToIntervention = async newIntervention => {
    try {
      setConvertToInterventionError("");
      setIsConvertingToIntervention(true);

      await Service.addIntervention({ ...newIntervention, question_result_id: convertToIntervention.question_result_id });

      setConvertToIntervention(null);
    } catch (error) {
      console.log("Error adding intervention: ", error);
      const addInterventionsError = error.response?.data?.errors ? error.response.data.errors[0] : "";
      setConvertToInterventionError(addInterventionsError);
    } finally {
      setIsConvertingToIntervention(false);
    }
  };

  const handleUpdateRemark = (question_result_id, index, remark) => {
    Service.updateQuestionResultRemark({ question_result_id, remark })
      .then(() => {
        onAnswerUpdate({
          index,
          shouldSave: false,
          type: "raw",
          value: remark,
          questionId: question_result_id,
        });
      })
      .catch(error => console.error("Error updating question remark", error));
  };

  const renderQuestions = () => {
    if (!questions) return t("no_questions").message || "No questions";
    let tyres_on_car = [],
      tyres_in_storage = [],
      tyres_on_truck = {},
      spareTiresCount = 0,
      // Default value for checklist set if none is passed for special cases, eg. when
      // displaying Pinned Questions list
      checklist = initialChecklist ? initialChecklist : { is_quality: false, type: 0 };
    const isQuality = checklist.is_quality;
    const { keep_importing_after_work_started } = location;

    let remarkWidescreenWidth = includeVAT ? (isAdvisedCriticalItemsSection ? 4 : 5) : isAdvisedCriticalItemsSection ? 5 : 6;
    let remarkComputerWidth = includeVAT ? (isAdvisedCriticalItemsSection ? 3 : 4) : isAdvisedCriticalItemsSection ? 4 : 5;

    if (keep_importing_after_work_started) {
      remarkWidescreenWidth += 2;
      remarkComputerWidth += 2;

      if (includeVAT) {
        remarkWidescreenWidth += 2;
        remarkComputerWidth += 2;
      }
    }

    return (
      <>
        {questions.map((question, index) => {
          let isScheduleEnabledByStatus =
            question.status === QUESTION_RESULT_STATUS.OK_WITH_REMARKS ||
            question.status === QUESTION_RESULT_STATUS.ADVISED ||
            question.status === QUESTION_RESULT_STATUS.NECESSARY;

          let activeImages = getActiveImages(question.images);
          let activeVideos = getActiveVideos(question.video);

          let questionTemplate = template;
          if (!questionTemplate && question.importantQuestionTemplate) questionTemplate = question.importantQuestionTemplate;

          if (!question.answers) {
            question.answers = [
              {
                encoded: "",
                order: 0,
                raw: question.raw,
                template: "",
              },
            ];
          }

          if (isImportantItem || isAdvisedCriticalItemsSection || !question.answers.some(e => ["tyre", "truck_tyre"].includes(e.template))) {
            return (
              <Grid className={`AppointmentChecklistGroup__item -item-status-${question.status}`} key={question.id}>
                {isAdvisedCriticalItemsSection && (
                  <>
                    <Grid.Column width={3} className="AppointmentChecklistGroup__item__appointmentInfo" style={{ display: "flex", alignItems: "center" }}>
                      <div>
                        {question.time_car_app && (moment.isMoment(question.time_car_app) || !question.time_car_app.startsWith("0001-01-01T00:00:00"))
                          ? moment(question.time_car_app).format("DD-MM-YYYY")
                          : ""}
                      </div>

                      <span className="-cursor-pointer" onClick={() => onAppointmentClick(question.appointment_id)}>
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginRight: "5px", fontSize: "15px" }} />
                        {question.wo_nr || 0}
                      </span>
                    </Grid.Column>
                  </>
                )}
                <Grid.Column
                  style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                  width={includeVAT ? (isAdvisedCriticalItemsSection ? 2 : 3) : isAdvisedCriticalItemsSection ? 3 : 4}
                  className="AppointmentChecklistGroup__item__question"
                >
                  {isCustomerCommunicationVisible && (
                    <Label
                      className="-cursor-pointer"
                      onClick={() => onChangeCustomerCommunication(!(selectedCustomerComm.findIndex(s => s.question_result_id === question.id) > -1), question.id)}
                    >
                      <Icon
                        color={`${selectedCustomerComm.findIndex(s => s.question_result_id === question.id) > -1 ? "green" : "red"}`}
                        name={`${selectedCustomerComm.findIndex(s => s.question_result_id === question.id) > -1 ? "eye" : "eye slash"}`}
                      />
                    </Label>
                  )}
                  {displayFrontIcon && question.pinned && !isCustomerCommunicationVisible && <Label>{renderPinTypeIcon(question.pin_history[0]?.pin_type_id)}</Label>}
                  {displayFrontIcon && question.snoozed && !isCustomerCommunicationVisible && (
                    <Label>
                      <Icon name="clock" />
                    </Label>
                  )}
                  <span title={question.title}>
                    {question.tyre_id && getTyreSeasonIcon(question.tyre.season)} {question.title}
                  </span>
                </Grid.Column>
                <Grid.Column widescreen={remarkWidescreenWidth} computer={remarkComputerWidth}>
                  <Can I="update_question_results_remark" the="car_checks">
                    <InlineInputIcon
                      type="textarea"
                      disabled={readOnlyRemarks || isCustomerCommunicationVisible}
                      placeholder={t("answer").message}
                      value={question.raw ? question.raw.trim() : ""}
                      isImportantItem={isImportantItem}
                      icon="save"
                      textAreaWidth="89%"
                      iconConfig={{
                        style: {
                          marginTop: "1em!important",
                        },
                      }}
                      onSave={value => handleUpdateRemark(question.id, index, value)}
                    />
                  </Can>
                </Grid.Column>

                {!keep_importing_after_work_started && (
                  <Grid.Column width={2}>
                    {renderBasedOnTemplateAndOptions({
                      template,
                      options: question.question_options,
                      item: "price_enabled",
                      toRender: (
                        <InlineInputIcon
                          type="price"
                          value={question.price}
                          icon="save"
                          leftIcon="euro"
                          onSave={value => {
                            if (isQuality) return;

                            if (!value) value = 0.0;

                            const request = {
                              index,
                              shouldSave: true,
                              type: "price",
                              value,
                              questionId: question.id,
                            };

                            if (
                              dashboardConfig["diagnose_overview_enabled"] &&
                              (appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_OPENED ||
                                appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED) &&
                              selectedCustomerComm.findIndex(s => s.question_result_id === question.id) > -1
                            ) {
                              onPriceChange(request);
                            } else {
                              onAnswerUpdate(request);
                            }
                          }}
                          config={{
                            placeholder: "0.00",
                            style: {
                              width: "8em",
                              paddingBottom: "0px",
                            },
                            disabled: isQuality || isCustomerCommunicationVisible || !isPriceEditable,
                          }}
                          displayValueFunc={getPriceForDisplay}
                        />
                      ),
                    })}
                  </Grid.Column>
                )}

                {!keep_importing_after_work_started && includeVAT && (
                  <Grid.Column width={2}>
                    {renderBasedOnTemplateAndOptions({
                      template,
                      options: question.question_options,
                      item: "price_enabled",
                      toRender: (
                        <InlineInputIcon
                          type="price"
                          value={question.price ? question.price * (1 + vat / 100) : 0.0}
                          icon="save"
                          leftIcon="euro"
                          onSave={value => {
                            if (!value) value = 0.0;
                            value = (value * 100) / (100 + vat);

                            if (isQuality) return;

                            const request = {
                              index,
                              shouldSave: true,
                              type: "price",
                              value,
                              questionId: question.id,
                            };

                            if (
                              dashboardConfig["diagnose_overview_enabled"] &&
                              (appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_OPENED ||
                                appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED) &&
                              selectedCustomerComm.findIndex(s => s.question_result_id === question.id) > -1
                            ) {
                              onPriceChange(request);
                            } else {
                              onAnswerUpdate(request);
                            }
                          }}
                          config={{
                            placeholder: "0.00",
                            style: {
                              width: "8em",
                              color: "#000",
                              paddingBottom: "0px",
                            },
                            disabled: isQuality || isCustomerCommunicationVisible || !isPriceEditable,
                          }}
                          displayValueFunc={getPriceForDisplay}
                        />
                      ),
                    })}
                  </Grid.Column>
                )}

                <Grid.Column
                  widescreen={isAdvisedCriticalItemsSection ? 3 : 4}
                  computer={isAdvisedCriticalItemsSection ? 4 : 5}
                  textAlign="right"
                  className="AppointmentChecklistGroup__item__modifiers -answer-status -text-right -no-padding-left -no-padding-right"
                >
                  {(!question.answers.length || !question.answers.every(a => a.template?.endsWith("signature"))) && (
                    <AnswerStatusSelector
                      className="-no-padding -pull-left"
                      style={{
                        width: "10%",
                      }}
                      disabled={isCustomerCommunicationVisible || !isEditable}
                      options={question.answers.some(a => a.template === "panic") ? ANSWER_STATUSES.filter(status => [3, 4].includes(status.value)) : ANSWER_STATUSES}
                      value={question.status}
                      onChange={value => {
                        if (isQuality) return;

                        onAnswerUpdate({
                          index,
                          shouldSave: true,
                          type: "status",
                          value,
                          questionId: question.id,
                        });
                      }}
                    />
                  )}
                  {question.solution_value && !isCustomerCommunicationVisible && question.solution_unit && question.solution_type && (
                    <Popup
                      position="top right"
                      trigger={
                        <Label>
                          <Icon name="wpforms" />
                        </Label>
                      }
                      content={
                        <div className="solution_element_content">
                          <p className="item">
                            <span className="unit">
                              <strong>{t("intervention").message || "Intervention"}</strong>
                            </span>
                            <span className="value">{question.solution_unit}</span>
                          </p>
                          <p className="item">
                            <span className="unit">
                              <strong>{t("value").message || "Value"}</strong>
                            </span>
                            <span className="value">{question.solution_value}</span>
                          </p>
                          <p className="item">
                            <span className="unit">
                              <strong>{t("type").message || "Type"}</strong>
                            </span>
                            <span className="value">{question.solution_type}</span>
                          </p>
                        </div>
                      }
                    />
                  )}
                  {(activeImages && activeImages.length > 0) || (dashboardConfig["video_enabled"] && activeVideos && activeVideos.length > 0) ? (
                    <Label
                      color="teal"
                      style={{
                        width: "auto",
                      }}
                      onClick={() => {
                        onMediaToggle(index);
                      }}
                    >
                      <Icon name="images" /> {dashboardConfig["video_enabled"] && activeVideos ? activeVideos.length + activeImages.length : activeImages.length}
                    </Label>
                  ) : (
                    ""
                  )}
                  {!isCustomerCommunicationVisible && checkCustomerComm(question.status, appointment.diagnose_overview_results, question.id)}
                  {/* Check which of the settings should be taken into account, version/dashboard config/list template, ie. check the order of precedence */}
                  {dashboardConfig["schedule_enabled"] &&
                    isScheduleEnabledByStatus &&
                    !isCustomerCommunicationVisible &&
                    renderBasedOnTemplateAndOptions({
                      template: questionTemplate,
                      options: question.question_options,
                      item: "snooze_enabled",
                      toRender: (
                        <Snooze
                          question_result={question}
                          appointment={isAdvisedCriticalItemsSection && question.appointment ? question.appointment : appointment}
                          snoozeLog={question.snooze_history}
                          onSnooze={snoozeInfo => {
                            onAnswerUpdate({
                              index,
                              questionId: question.id,
                              type: "snooze",
                              shouldSave: false,
                              value: snoozeInfo,
                            });
                          }}
                          onDelete={() => {
                            onAnswerUpdate({
                              index,
                              questionId: question.id,
                              type: "snooze",
                              shouldSave: false,
                              value: [],
                            });
                          }}
                        />
                      ),
                    })}
                  {dashboardConfig["pin_visible"] &&
                    !isCustomerCommunicationVisible &&
                    renderBasedOnTemplateAndOptions({
                      template: questionTemplate,
                      options: question.question_options,
                      item: "pin_enabled",
                      toRender: (
                        <Pin
                          question_result={question}
                          appointment={isAdvisedCriticalItemsSection && question.appointment ? question.appointment : appointment}
                          pinLog={question.pin_history}
                          onPin={pinLog => {
                            onAnswerUpdate({
                              index,
                              questionId: question.id,
                              type: "pin",
                              shouldSave: false,
                              value: pinLog,
                            });
                          }}
                          onDelete={() => {
                            onAnswerUpdate({
                              index,
                              questionId: question.id,
                              type: "pin",
                              shouldSave: false,
                              value: [],
                            });
                          }}
                        />
                      ),
                    })}
                  {/* Until all lists have templates use a fallback for rendering special cases of within-list settings*/}
                  {/* Hide below if the list is Extra Parts list ie. type 4 */}
                  {!keep_importing_after_work_started && (
                    <>
                      {question.status !== QUESTION_RESULT_STATUS.OK && !questionTemplate && checklist.checklist_type !== CHECKLIST_TYPE.EXTRA_PART && (
                        <ThumbToggle
                          status={question.customer_approved}
                          onClick={() => {
                            if (isQuality) return;

                            onAnswerUpdate({
                              index,
                              questionId: question.id,
                              type: "customer_approved",
                              shouldSave: true,
                              value: !question.customer_approved,
                            });
                          }}
                          disabled={isCustomerCommunicationVisible || !isEditable || question.mechanic_fixed}
                        />
                      )}
                      {question.status !== QUESTION_RESULT_STATUS.OK &&
                        questionTemplate &&
                        renderBasedOnTemplateAndOptions({
                          template: questionTemplate,
                          item: "customer_ok_enabled",
                          toRender: (
                            <ThumbToggle
                              status={question.customer_approved}
                              onClick={() => {
                                if (isQuality) return;

                                getTotalPrice();
                                onAnswerUpdate({
                                  index,
                                  questionId: question.id,
                                  type: "customer_approved",
                                  shouldSave: true,
                                  value: !question.customer_approved,
                                });
                              }}
                              disabled={isCustomerCommunicationVisible || !isEditable || question.mechanic_fixed}
                            />
                          ),
                        })}
                    </>
                  )}

                  {/* Until all lists have templates use a fallback for rendering special cases of within-list settings*/}
                  {/* Hide below if the list is Extra Parts list ie. type 4 */}
                  {isAdvisedCriticalItemsSection && (
                    <div onClick={() => onToggleHistoryItem(question)} style={{ display: "inline-block" }}>
                      <Label color={question.is_hidden_in_history ? "blue" : null}>
                        <Icon name="history" />
                      </Label>
                    </div>
                  )}
                  <Can I="add" the="interventions">
                    {keep_importing_after_work_started &&
                      !isCustomerCommunicationVisible &&
                      isEditable &&
                      [DMS.KEYLOOP_MENUS, DMS.KEYLOOP_JOBS].includes(location.dms_id) &&
                      !appointment.interventions?.some(intervention => intervention.question_result_id === question.id) && (
                        <Label
                          onClick={() =>
                            setConvertToIntervention({
                              title: question.title,
                              subitemDescription: `${t("claire_remark").message || "Claire remark"} ${question.raw}`,
                              question_result_id: question.id,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} style={{ fontSize: "1.4em" }} />
                        </Label>
                      )}
                  </Can>

                  {!keep_importing_after_work_started && !isAdvisedCriticalItemsSection && (
                    <>
                      {(isFinalCheckDone || question.mechanic_fixed) &&
                        question.customer_approved &&
                        !questionTemplate &&
                        checklist.checklist_type !== CHECKLIST_TYPE.EXTRA_PART &&
                        renderBasedOnTemplateAndOptions({
                          template: questionTemplate,
                          item: "ready_enabled",
                          toRender: <span className="AnswerReadyStatus">{renderReadyStatus(question.mechanic_fixed)}</span>,
                        })}
                      {(isFinalCheckDone || question.mechanic_fixed) &&
                        question.customer_approved &&
                        questionTemplate &&
                        renderBasedOnTemplateAndOptions({
                          template: questionTemplate,
                          item: "ready_enabled",
                          toRender: <span className="AnswerReadyStatus">{renderReadyStatus(question.mechanic_fixed)}</span>,
                        })}
                      {((!isFinalCheckDone && !question.mechanic_fixed) || !question.customer_approved) &&
                        renderBasedOnTemplateAndOptions({
                          template: questionTemplate,
                          item: "ready_enabled",
                          toRender: <span className="AnswerReadyStatus">{renderReadyStatus(question.mechanic_fixed)}</span>,
                        })}
                    </>
                  )}
                </Grid.Column>

                {question.mechanic_notes && !isCustomerCommunicationVisible && <MechanicNote note={question.mechanic_notes} />}

                {isImportantItem && question.pin_history?.some(log => log.visible_important_items) && (
                  <div className="ImportantItems-notes">
                    {question.pin_history.map(
                      log =>
                        log.visible_important_items && (
                          <Grid.Row className="note-subline-row" key={log.id}>
                            <Grid.Column width={16}>
                              <div className={`note-subline ${log.keep_parts ? "keep-parts" : ""}`}>
                                {log.keep_parts && (t("keep_parts_prefix").message || "KEEP PARTS") + " "}
                                {log.note_attachments?.length > 0 && (
                                  <FileDropDown
                                    resource="pin"
                                    files={pinLogAttachmentList(log)}
                                    trigger={getFilesButton({ count: log.note_attachments.length })}
                                    showPreview
                                    showCount={false}
                                    showIcon={true}
                                    disableUpload
                                  />
                                )}
                                {log.note}
                              </div>
                            </Grid.Column>
                          </Grid.Row>
                        )
                    )}
                  </div>
                )}
                {question.mediaVisible &&
                  renderMedia(
                    isCustomerCommunicationVisible,
                    isAdvisedCriticalItemsSection ? question.appointment : appointment,
                    activeImages,
                    dashboardConfig["video_enabled"] ? activeVideos : [],
                    onMediaClick,
                    update => {
                      if (isQuality) return;

                      onAnswerUpdate({
                        questionId: question.id,
                        index,
                        allImages: question.images,
                        allVideos: question.video,
                        ...update,
                      });
                    },
                    !readOnly,
                    [],
                    null,
                    location
                  )}
              </Grid>
            );
          } else if (question.answers.some(e => e.template === "truck_tyre")) {
            let tiresGroup;
            if (question.tyre_position === TYRE_POSITION.TRUCK_SPARE) {
              question.spareTiresCount = ++spareTiresCount;
              tiresGroup = `spare_${spareTiresCount}`;
            } else tiresGroup = question.truck_axle;

            if (!tyres_on_truck[tiresGroup]) tyres_on_truck[tiresGroup] = [];

            tyres_on_truck[tiresGroup].push(question);

            const price_enabled = question.question_options.some(qo => qo.name === "price_enabled" && qo.value);

            if (questions.length - 1 !== index) return null;

            return (
              <Grid className="AppointmentChecklistGroup__item" key={index}>
                <AppointmentTruckTyres
                  price_enabled={price_enabled}
                  isPriceEditable={isPriceEditable}
                  isEditable={isEditable && !isCustomerCommunicationVisible}
                  appointment={appointment}
                  location={location}
                  displayCustomcomStatus={checkCustomerComm}
                  onChangeCustomerCommunication={onChangeCustomerCommunication}
                  includeVAT={includeVAT}
                  vat={vat}
                  selectedCustomerComm={selectedCustomerComm}
                  isCustomerCommunicationVisible={isCustomerCommunicationVisible}
                  answer={tyres_on_truck}
                  tyreMediaVisible={tyreMediaVisible}
                  onAnswerUpdate={update => {
                    if (isQuality) return;

                    if (
                      update.type === "price" &&
                      dashboardConfig["diagnose_overview_enabled"] &&
                      (appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_OPENED ||
                        appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED) &&
                      selectedCustomerComm.some(s => s.question_result_id === update.questionId)
                    ) {
                      onPriceChange(update);
                    } else {
                      onAnswerUpdate(update);
                    }
                  }}
                  onMediaClick={onMediaClick}
                  onTyreMediaToggle={onTyreMediaToggle}
                  dashboardConfig={dashboardConfig}
                  readOnlyRemarks={readOnlyRemarks}
                />
              </Grid>
            );
          } else {
            if (TYRE_POSITION.IS_ON_CAR(question.tyre_position)) tyres_on_car.push(question);
            else tyres_in_storage.push(question);

            if (question.tyre_position !== TYRE_POSITION.CAR_FRONT_LEFT) return null;

            const price_enabled = question.question_options.some(qo => qo.name === "price_enabled" && qo.value);

            return (
              <Grid className="AppointmentChecklistGroup__item" key={index}>
                <AppointmentTyres
                  price_enabled={price_enabled}
                  isPriceEditable={isPriceEditable}
                  isEditable={isEditable && !isCustomerCommunicationVisible}
                  appointment={appointment}
                  location={location}
                  displayCustomcomStatus={checkCustomerComm}
                  onChangeCustomerCommunication={onChangeCustomerCommunication}
                  includeVAT={includeVAT}
                  vat={vat}
                  selectedCustomerComm={selectedCustomerComm}
                  isCustomerCommunicationVisible={isCustomerCommunicationVisible}
                  answer={{
                    on_car: tyres_on_car,
                    in_storage: tyres_in_storage,
                  }}
                  tyreMediaVisible={tyreMediaVisible}
                  onAnswerUpdate={update => {
                    if (isQuality) return;

                    if (
                      update.type === "price" &&
                      dashboardConfig["diagnose_overview_enabled"] &&
                      (appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_OPENED ||
                        appointment.customcom_status === COMMUNICATION_STATUSES.DIAGNOSE_OVERVIEW_ANSWERED) &&
                      selectedCustomerComm.some(s => s.question_result_id === update.questionId)
                    ) {
                      onPriceChange(update);
                    } else {
                      onAnswerUpdate(update);
                    }
                  }}
                  onMediaClick={onMediaClick}
                  onTyreMediaToggle={onTyreMediaToggle}
                  onTyreReplacementUpdate={onTyreReplacementAnswerUpdate}
                  onTyreReplaceDelete={onTyreReplaceDelete}
                  dashboardConfig={dashboardConfig}
                  readOnlyRemarks={readOnlyRemarks}
                />
              </Grid>
            );
          }
        })}
      </>
    );
  };

  return (
    <>
      {renderHeaders()}

      {isLoading && (
        <div className="Loader-Placeholder">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
          <section>{t("loading").message || "Loading"}</section>
        </div>
      )}

      {!isLoading && renderQuestions()}
      {convertToIntervention && (
        <AddAppointmentIntervention
          keyloopEnabled={keyloopEnabled}
          title={convertToIntervention.title}
          subitemDescription={convertToIntervention.subitemDescription}
          appointment_id={appointment.id}
          isAddingNewIntervention={isConvertingToIntervention}
          addInterventionsError={convertToInterventionError}
          dms_id={location.dms_id}
          onSubmit={handleConvertToIntervention}
          onClose={() => setConvertToIntervention(null)}
        />
      )}
    </>
  );
};

export default withTranslation()(AppointmentQuestionResult);
