import moment from "moment";

import Service from "./service";
import {
  addBrandToStorage,
  applyUpdate as applyUpdateOnAppointments,
  filterAppointments as applyFiltersOnAppointments,
  getBrandFromStorage,
  getUniqueCarMakes,
  prepareChecks,
  removeBrandFromStorage,
  sortAppointments,
  updateAppointmentStatusIdentifier,
} from "./util";
import { handleErrorMessage } from "../Auth/util";
import { getPreference, setPreference } from "../../util/preferences";
import { DMS } from "../DMSv3/util";
import { WO_FILTERS } from "./common";

const APPOINTMENT_DATE_COLUMN = "AppointmentDateColumn";
const SELECTED_UPDATED_BY_COLUMN_PREFERENCE = "selected-updated-by-column";

export const APPOINTMENTS_ACTION_TYPES = {
  APPOINTMENTS_LOADING: "appointments/APPOINTMENTS_LOADING",
  APPOINTMENT_LOADING: "appointments/APPOINTMENT_LOADING",
  APPOINTMENT_UPDATES_APPLIED: "appointments/APPOINTMENT_UPDATES_APPLIED",
  NO_UPDATE_TO_APPLY: "appointments/NO_UPDATE_TO_APPLY",
  DESELECT_APPOINTMENT_SUCCESS: "appointments/DESELECT_APPOINTMENT_SUCCESS",
  FILTER_APPOINTMENTS_FAIL: "appointments/FILTER_APPOINTMENTS_FAIL",
  FILTER_APPOINTMENTS_SUCCESS: "appointments/FILTER_APPOINTMENTS_SUCCESS",
  GET_APPOINTMENT_FAIL: "appointments/GET_APPOINTMENT_FAIL",
  GET_APPOINTMENT_SUCCESS: "appointments/GET_APPOINTMENT_SUCCESS",
  GET_APPOINTMENTS_FAIL: "appointments/GET_APPOINTMENTS_FAIL",
  GET_APPOINTMENTS_SUCCESS: "appointments/GET_APPOINTMENTS_SUCCESS",
  GET_CHECKS_FAIL: "appointments/GET_CHECKS_FAIL",
  GET_CHECKS_SUCCESS: "appointments/GET_CHECKS_SUCCESS",
  UPDATE_QUESTION_ITEM: "appointments/UPDATE_QUESTION_ITEM",
  GET_SNOOZED_ITEMS_SUCCESS: "appointments/GET_SNOOZED_ITEMS_SUCCESS",
  GET_SNOOZED_ITEMS_FAIL: "appointments/GET_SNOOZED_ITEMS_FAIL",
  STATUS_FILTER_RESET: "appointments/STATUS_FILTER_RESET",
  STATUS_FILTER_UPDATED: "appointments/STATUS_FILTER_UPDATED",
  CAR_MAKES_UPDATED: "appointments/CAR_MAKES_UPDATED",
  SEARCH_APPOINTMENTS_SUCCESS: "appointments/SEARCH_APPOINTMENTS_SUCCESS",
  SEARCH_APPOINTMENTS_FAIL: "appointments/SEARCH_APPOINTMENTS_FAIL",
  WEB_SOCKET_CONNECTION_UPDATE: "appointments/WEB_SOCKET_CONNECTION_UPDATE",
  WEB_SOCKET_APPOINTMENTS_UPDATE: "appointments/WEB_SOCKET_APPOINTMENTS_UPDATE",
  WEB_SOCKET_APPOINTMENTS_REFRESH: "appointments/WEB_SOCKET_APPOINTMENTS_REFRESH",
  WEB_SOCKET_APPOINTMENTS_ASSIGNMENT_UPDATED: "appointments/WEB_SOCKET_APPOINTMENTS_ASSIGNMENT_UPDATED",
  WEB_SOCKET_MECHANIC_AVAILABILITY_UPDATED: "appointments/WEB_SOCKET_MECHANIC_AVAILABILITY_UPDATED",
  DAYPLANNER_SOCKET_UPDATE_APPLIED: "appointments/DAYPLANNER_SOCKET_UPDATE_APPLIED",
  DELETE_CHECK: "appointments/DELETE_CHECK",
  ICONS_FILTER_UPDATED: "appointments/ICONS_FILTER_UPDATED",
  RESET_ICONS_FILTERS: "appointments/RESET_ICONS_FILTERS",
  APPOINTMENT_DATE_TIME_CHANGE: "appointments/APPOINTMENT_DATE_TIME_CHANGE",
  APPOINTMENTS_NAME_COLUMN_SELECTED: "appointments/APPOINTMENTS_NAME_COLUMN_SELECTED",
  APPOINTMENTS_UPDATED_BY_COLUMN_SELECTED: "appointments/APPOINTMENTS_UPDATED_BY_COLUMN_SELECTED",
  DETAIL_PAGE_ONLINE_USERS_UPDATE: "appointments/DETAIL_PAGE_ONLINE_USERS_UPDATE",
  ADD_APPOINTMENT_NOTE: "appointments/ADD_APPOINTMENT_NOTE",
  UPDATE_APPOINTMENT_NOTE: "appointments/UPDATE_APPOINTMENT_NOTE",
  DELETE_APPOINTMENT_NOTE: "appointments/DELETE_APPOINTMENT_NOTE",
  HIDE_TITLE_BAR_NOTIF: "appointments/HIDE_TITLE_BAR_NOTIF",
  SHOW_TITLE_BAR_NOTIF: "appointments/SHOW_TITLE_BAR_NOTIF",
  CLEAR_APPOINTMENTS: "appointments/CLEAR_APPOINTMENTS",
  GET_SNOOZE_APPOINTMENT_HISTORY_SUCCESS: "appointment/GET_SNOOZE_APPOINTMENT_HISTORY_SUCCESS",
  GET_SNOOZE_APPOINTMENT_HISTORY_FAIL: "appointments/GET_SNOOZE_APPOINTMENT_HISTORY_FAIL",
  STARTS_LOADING_SNOOZE_APPOINTMENT_HISTORY: "appointments/STARTS_LOADING_SNOOZE_APPOINTMENT_HISTORY",
  UPDATE_SNOOZE_APPOINTMENT_HISTORY_ITEM: "appointments/UPDATE_SNOOZE_APPOINTMENT_HISTORY_ITEM",
  DELETE_SNOOZE_APPOINTMENT_HISTORY_ITEM: "appointments/DELETE_SNOOZE_APPOINTMENT_HISTORY_ITEM",
  GET_APPOINTMENT_ADVISED_CRITICAL_HISTORY_SUCCESS: "appointment/GET_APPOINTMENT_ADVISED_CRITICAL_HISTORY_SUCCESS",
  STARTS_LOADING_APPOINTMENT_ADVISED_CRITICAL_HISTORY: "appointments/STARTS_LOADING_APPOINTMENT_ADVISED_CRITICAL_HISTORY",
  GET_APPOINTMENT_ADVISED_CRITICAL_HISTORY_FAIL: "appointments/GET_APPOINTMENT_ADVISED_CRITICAL_HISTORY_FAIL",
  UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM: "appointments/UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM",
  RESET_APPOINTMENTS_STORE: "appointments/RESET_APPOINTMENTS_STORE",
  WO_FILTER_SELECTED: "appointments/WO_FILTER_SELECTED",
};

export function hideTitleBarNotif() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.HIDE_TITLE_BAR_NOTIF });
}

export function showTitleBarNotif() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.SHOW_TITLE_BAR_NOTIF });
}

export function appointmentsLoading() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENTS_LOADING });
}

export function appointmentLoading() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_LOADING });
}

export function deleteAppointmentNote(id) {
  return (dispatch, getState) => {
    const selectedAppointment = getState().appointments.selectedAppointment;

    selectedAppointment.notes = selectedAppointment.notes.filter(note => note.id !== id);

    dispatch({ type: APPOINTMENTS_ACTION_TYPES.DELETE_APPOINTMENT_NOTE, selectedAppointment: { ...selectedAppointment } });
  };
}

export function updateAppointmentNote(id, updatedNote) {
  return (dispatch, getState) => {
    const selectedAppointment = getState().appointments.selectedAppointment;

    selectedAppointment.notes = selectedAppointment.notes.map(note => {
      if (note.id === id) {
        return {
          ...note,
          ...updatedNote,
        };
      } else return note;
    });
    dispatch({ type: APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_NOTE, selectedAppointment: { ...selectedAppointment } });
  };
}

export function addAppointmentAttachment(attachment) {
  return (dispatch, getState) => {
    const selectedAppointment = getState().appointments.selectedAppointment;
    if (!selectedAppointment.attachments) selectedAppointment.attachments = [attachment];
    else selectedAppointment.attachments = [...selectedAppointment.attachments, attachment];

    dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED, subtype: "SELECTED_APPOINTMENT", appointment: { ...selectedAppointment } });
  };
}

export function deleteAppointmentAttachment(url) {
  return (dispatch, getState) => {
    const selectedAppointment = getState().appointments.selectedAppointment;
    selectedAppointment.attachments = selectedAppointment.attachments.filter(attachment => attachment.url !== url);

    dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED, subtype: "SELECTED_APPOINTMENT", appointment: { ...selectedAppointment } });
  };
}

export function getAppointments(requestData, shouldClearAppointments = false) {
  return (dispatch, getState) => {
    dispatch(appointmentsLoading());

    Service.getAppointments(requestData)
      .then(response => {
        let appointments = response.data?.data?.appointments || [];

        if (appointments && appointments.length > 0) {
          appointments.forEach(a => {
            updateAppointmentStatusIdentifier(a);
          });

          appointments = sortAppointments(appointments, getState().global.selectedLocation.statuses);
        }

        const FILTERS = getState().appointments.appointmentsFilters;
        FILTERS.searchTerm = requestData.searchTerm;
        // Set unique car makes
        let oldUniqueCarMakes = getState().appointments.uniqueCarMakes;
        let uniqueCarMakes = getUniqueCarMakes(
          appointments.map(function (a) {
            return a.car_make;
          }),
          oldUniqueCarMakes,
          FILTERS.carMakes
        );

        let appointmentsVisible = appointments;

        if (FILTERS) {
          appointmentsVisible = applyFiltersOnAppointments(appointmentsVisible, FILTERS);
        }

        let appointmentsFilters = getState().appointments.appointmentsFilters;

        dispatch(getAppointmentsSuccess(appointments, appointmentsVisible, uniqueCarMakes, appointmentsFilters, requestData.date, requestData.since_date || null));
      })
      .catch(error => {
        if (shouldClearAppointments) dispatch(clearAppointments());

        dispatch(getAppointmentsFail(handleErrorMessage(error)));
      });
  };
}

export function getAppointmentsSuccess(appointments, appointmentsVisible, uniqueCarMakes, appointmentsFilters, selectedDate, sinceDate) {
  return dispatch =>
    dispatch({
      type: APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENTS_SUCCESS,
      appointments,
      appointmentsVisible,
      uniqueCarMakes,
      appointmentsFilters,
      selectedDate,
      sinceDate,
    });
}

export function getAppointmentsFail(errorMessages) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENTS_FAIL, errorMessages });
}

export function filterAppointments(appointmentsFilters) {
  return (dispatch, getState) => {
    const ALL_APPOINTMENTS = getState().appointments.appointments;

    if (!ALL_APPOINTMENTS || ALL_APPOINTMENTS.length === 0) {
      dispatch({ type: APPOINTMENTS_ACTION_TYPES.FILTER_APPOINTMENTS_FAIL });
      return;
    }

    let appointmentsVisible = ALL_APPOINTMENTS;
    if (!appointmentsFilters.reset) {
      appointmentsVisible = applyFiltersOnAppointments(ALL_APPOINTMENTS, appointmentsFilters);
    }

    dispatch({ type: APPOINTMENTS_ACTION_TYPES.FILTER_APPOINTMENTS_SUCCESS, appointmentsVisible, appointmentsFilters });
  };
}

export function resetAppointmentsStore() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.RESET_APPOINTMENTS_STORE });
}

export function woFilterSelect(woFilterSelected) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.WO_FILTER_SELECTED, woFilterSelected });
}

export function searchAppointments({ dmsId, ...requestData }) {
  return dispatch => {
    dispatch(appointmentsLoading());

    const isKeyloopLocation = [DMS.KEYLOOP_MENUS, DMS.KEYLOOP_JOBS].includes(dmsId);
    const isNextlaneLocation = [DMS.NEXTLANE].includes(dmsId);
    const search_results = {};

    if (isKeyloopLocation) {
      Service.searchKeyloopAppointments(requestData)
        .then(response => {
          if (response?.data?.data?.claire?.length) {
            let results = response.data.data.claire.map((r, i) => ({
              id: r.id,
              car_id: r.car_id,
              appointment_id: r.id,
              title: i + "",
              make: r.car_make,
              model: r.car_model,
              date: r.time_car_app,
              wo_number: r.wo_nr,
              ref_nr: r.ref_nr,
              reg_number: r.reg_number,
              status_identifier: r.appointment_status_identifier,
              dealer_location_name: r.dealer_location?.name || "",
              search_category: "claire",
            }));

            results.sort((a, b) => (b.date > a.date ? 1 : -1));
            search_results.claire = { name: "Claire", results };
          }

          if (response?.data?.data?.keyloop?.length) {
            let results = response.data.data.keyloop.map((r, i) => ({
              id: r.repairOrderId,
              title: i + "",
              contact_name: r.contact.name || "",
              make: r.vehicle.description,
              due_in: r.appointment.dueInDateTime,
              due_out: r.appointment.dueOutDateTime,
              wo_number: r.repairOrderId,
              ref_nr: r.vehicle.vin,
              reg_number: r.vehicle.licensePlate,
              status_identifier: r.status,
              imported: r.imported,
              search_category: "keyloop",
            }));

            results.sort((a, b) => (b.due_in > a.due_in ? 1 : -1));
            search_results.keyloop = { name: "Keyloop", results };
          }

          dispatch(searchAppointmentsSuccess(search_results));
        })
        .catch(error => {
          dispatch(searchAppointmentsFail(handleErrorMessage(error)));
        });
    } else if (isNextlaneLocation) {
      Service.searchNextlaneAppointments(requestData)
        .then(response => {
          if (response?.data?.data?.claire?.length) {
            let results = response.data.data.claire.map((r, i) => ({
              id: r.id,
              car_id: r.car_id,
              appointment_id: r.id,
              title: i + "",
              make: r.car_make,
              model: r.car_model,
              date: r.time_car_app,
              wo_number: r.wo_nr,
              ref_nr: r.ref_nr,
              reg_number: r.reg_number,
              status_identifier: r.appointment_status_identifier,
              dealer_location_name: r.dealer_location?.name || "",
              search_category: "claire",
            }));

            results.sort((a, b) => (b.date > a.date ? 1 : -1));
            search_results.claire = { name: "Claire", results };
          }

          if (response?.data?.data?.nextlane?.length) {
            let results = response.data.data.nextlane.map((r, i) => ({
              id: r.repairOrderId,
              title: i + "",
              contact_name: r.contact.name || "",
              make: r.vehicle.description,
              due_in: r.appointment.dueInDateTime,
              due_out: r.appointment.dueOutDateTime,
              wo_number: r.repairOrderId,
              ref_nr: r.vehicle.vin,
              reg_number: r.vehicle.licensePlate,
              status_identifier: r.status,
              imported: r.imported,
              search_category: "nextlane",
            }));

            results.sort((a, b) => (b.due_in > a.due_in ? 1 : -1));
            search_results.nextlane = { name: "Nextlane", results };
          }

          dispatch(searchAppointmentsSuccess(search_results));
        })
        .catch(error => {
          dispatch(searchAppointmentsFail(handleErrorMessage(error)));
        });
    } else {
      Service.searchAppointments(requestData)
        .then(response => {
          if (response?.data?.data?.length) {
            let results = response.data.data.map((r, i) => ({
              id: r.id,
              car_id: r.car_id,
              appointment_id: r.id,
              title: i + "",
              make: r.car_make,
              model: r.car_model,
              date: r.time_car_app,
              wo_number: r.wo_nr,
              ref_nr: r.ref_nr,
              reg_number: r.reg_number,
              status_identifier: r.appointment_status_identifier,
              dealer_location_name: r.dealer_location?.name || "",
              search_category: "claire",
            }));

            results.sort((a, b) => (b.date > a.date ? 1 : -1));
            search_results.claire = { name: "Claire", results };
          }
          dispatch(searchAppointmentsSuccess(search_results));
        })
        .catch(error => {
          dispatch(searchAppointmentsFail(handleErrorMessage(error)));
        });
    }
  };
}

export function searchAppointmentsSuccess(searchResults) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.SEARCH_APPOINTMENTS_SUCCESS, searchResults });
}

export function searchAppointmentsFail(errorMessages) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.SEARCH_APPOINTMENTS_FAIL, errorMessages });
}

export function noUpdateToApply() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.NO_UPDATE_TO_APPLY });
}

export function applyUpdateToAppointment(updatePayload, skipMainTableUpdate) {
  const { body, _topic } = updatePayload;
  const { update } = body;

  return async (dispatch, getState) => {
    if (!update) {
      dispatch(noUpdateToApply());
      return;
    }

    let appointments = getState().appointments.appointments ?? [];
    let selectedAppointment = getState().appointments.selectedAppointment;
    let selectedAppointmentChecks = getState().appointments.selectedAppointmentChecks;

    const user = getState().auth.user;
    const notificationElements = getState().global.notificationElements || [];

    if (_topic === "CarUpdateMessage") {
      if (selectedAppointment?.car?.id === body.car_id) {
        selectedAppointment.car = { ...selectedAppointment.car, ...update };
        return dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED, subtype: "SELECTED_APPOINTMENT", appointment: { ...selectedAppointment } });
      }

      return dispatch(noUpdateToApply());
    }

    if (selectedAppointment) {
      if (_topic === "SnoozeUpdateMessage") dispatch(updateSnoozedHistory(update));
      if (["PinUpdateMessage", "QuestionResultUpdateMessage"].includes(_topic)) dispatch(updateQuestionHistory(update));

      if (_topic === "SnoozeDeleteMessage") dispatch(deleteSnoozedHistory(update));
      if (_topic === "PinDeleteMessage") dispatch(deleteQuestionHistory(update));
    }

    // Update currently open/selected appointment
    if (selectedAppointment?.id === body.appointment_id) {
      const isChecklistUpdated = Array.isArray(update.checklists);
      const isFinalCheckDBBUpdated = _topic === "AppointmentUpdatedMessage" && update.final_car_check_dbb_status;

      if (_topic === "AppointmentUpdatedMessage" && update.check_deleted) {
        dispatch(getAppointment(selectedAppointment.id));
        dispatch(getChecks(selectedAppointment.id));
      } else if (_topic === "PinUpdateMessage") {
        const { question_result_id, intervention_id } = update;

        if (question_result_id) {
          let question = null;
          let checkIdx = -1;
          selectedAppointmentChecks.findIndex((check, i) => {
            checkIdx = i;
            question = check.question_items.find(qi => qi.id === question_result_id);
            return !!question;
          });

          if (question) {
            updatePinHistory(question, update);

            const check = selectedAppointmentChecks[checkIdx];
            if (check.sublists && check.sublists[question.group_order + "-" + question.group_name]?.items) {
              const sub = check.sublists[question.group_order + "-" + question.group_name].items.findIndex(i => i.id === question_result_id);
              if (sub > -1) check.sublists[question.group_order + "-" + question.group_name].items[sub] = question;
            }

            selectedAppointmentChecks[checkIdx] = { ...check };
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.UPDATE_QUESTION_ITEM, selectedAppointmentChecks: [...selectedAppointmentChecks] });
          }
        } else if (intervention_id) {
          const interventionIndex = selectedAppointment.interventions.findIndex(i => i.id === intervention_id);

          if (interventionIndex > -1) {
            const intervention = selectedAppointment.interventions[interventionIndex];
            updatePinHistory(intervention, update);

            selectedAppointment.interventions = [...selectedAppointment.interventions];
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED, subtype: "SELECTED_APPOINTMENT", appointment: { ...selectedAppointment } });
          }
        }

        return; // we only care to update the detail page, another WS message is sent to update pinCount
      } else if (_topic === "PinDeleteMessage") {
        const { question_result_id, intervention_id } = update;

        if (question_result_id) {
          let question = null;
          let checkIdx = -1;
          selectedAppointmentChecks.findIndex((check, i) => {
            checkIdx = i;
            question = check.question_items.find(qi => qi.id === question_result_id);
            return !!question;
          });

          if (question) {
            deletePinHistory(question);

            const check = selectedAppointmentChecks[checkIdx];
            if (check.sublists && check.sublists[question.group_order + "-" + question.group_name]?.items) {
              const sub = check.sublists[question.group_order + "-" + question.group_name].items.findIndex(i => i.id === question_result_id);
              if (sub > -1) check.sublists[question.group_order + "-" + question.group_name].items[sub] = question;
            }

            selectedAppointmentChecks[checkIdx] = { ...check };
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.UPDATE_QUESTION_ITEM, selectedAppointmentChecks: [...selectedAppointmentChecks] });
          }
        } else if (intervention_id) {
          const interventionIndex = selectedAppointment.interventions.findIndex(i => i.id === intervention_id);
          if (interventionIndex > -1) {
            deletePinHistory(selectedAppointment.interventions[interventionIndex]);

            selectedAppointment.interventions = [...selectedAppointment.interventions];
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED, subtype: "SELECTED_APPOINTMENT", appointment: { ...selectedAppointment } });
          }
        }

        return; // we only care to update the detail page, another WS message is sent to update pinCount
      } else if (_topic === "SnoozeUpdateMessage") {
        const { question_result_id, intervention_id } = update;

        if (question_result_id) {
          let question = null;
          let checkIdx = -1;
          selectedAppointmentChecks.findIndex((check, i) => {
            checkIdx = i;
            question = check.question_items.find(qi => qi.id === question_result_id);
            return !!question;
          });

          if (question) {
            updateSnoozeItem(question, update);

            const check = selectedAppointmentChecks[checkIdx];
            if (check.sublists && check.sublists[question.group_order + "-" + question.group_name]?.items) {
              const sub = check.sublists[question.group_order + "-" + question.group_name].items.findIndex(i => i.id === question_result_id);
              if (sub > -1) check.sublists[question.group_order + "-" + question.group_name].items[sub] = question;
            }

            selectedAppointmentChecks[checkIdx] = { ...check };
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.UPDATE_QUESTION_ITEM, selectedAppointmentChecks: [...selectedAppointmentChecks] });
          }
        } else if (intervention_id) {
          const interventionIndex = selectedAppointment.interventions.findIndex(i => i.id === intervention_id);

          if (interventionIndex > -1) {
            const intervention = selectedAppointment.interventions[interventionIndex];
            updateSnoozeItem(intervention, update);

            selectedAppointment.interventions = [...selectedAppointment.interventions];
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED, subtype: "SELECTED_APPOINTMENT", appointment: { ...selectedAppointment } });
          }
        }

        return;
      } else if (_topic === "SnoozeDeleteMessage") {
        const { question_result_id, intervention_id } = update;

        if (question_result_id) {
          let question = null;
          let checkIdx = -1;
          selectedAppointmentChecks.findIndex((check, i) => {
            checkIdx = i;
            question = check.question_items.find(qi => qi.id === question_result_id);
            return !!question;
          });

          if (question) {
            deleteSnoozeItem(question);

            const check = selectedAppointmentChecks[checkIdx];
            if (check.sublists && check.sublists[question.group_order + "-" + question.group_name]?.items) {
              const sub = check.sublists[question.group_order + "-" + question.group_name].items.findIndex(i => i.id === question_result_id);
              if (sub > -1) check.sublists[question.group_order + "-" + question.group_name].items[sub] = question;
            }

            selectedAppointmentChecks[checkIdx] = { ...check };
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.UPDATE_QUESTION_ITEM, selectedAppointmentChecks: [...selectedAppointmentChecks] });
          }
        } else if (intervention_id) {
          const interventionIndex = selectedAppointment.interventions.findIndex(i => i.id === intervention_id);
          if (interventionIndex > -1) {
            deleteSnoozeItem(selectedAppointment.interventions[interventionIndex]);

            selectedAppointment.interventions = [...selectedAppointment.interventions];
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED, subtype: "SELECTED_APPOINTMENT", appointment: { ...selectedAppointment } });
          }
        }

        return;
      } else if (_topic === "TyreReplacementUpdateMessage") {
        const { check_id, question_result_id } = body;
        const { tyre_replacement_id } = update;

        const checkIdx = selectedAppointmentChecks.findIndex(check => check.id === check_id);

        if (checkIdx > -1) {
          const check = selectedAppointmentChecks[checkIdx];
          const questionIdx = check.question_items.findIndex(c => c.id === question_result_id);

          if (questionIdx > -1) {
            if (Array.isArray(check.question_items[questionIdx].tyre_replacements)) {
              if (check.question_items[questionIdx].tyre_replacements.length > 1) {
                // when a replacement is approved, the others need to be set to false, so we first set them all to false, and below the update set the correct one to true if needed
                check.question_items[questionIdx].tyre_replacements.forEach(r => (r.customer_answer = false));
              }

              const replacementIdx = check.question_items[questionIdx].tyre_replacements.findIndex(trep => trep.tyre_replacement_id === tyre_replacement_id);
              if (replacementIdx > -1) {
                check.question_items[questionIdx].tyre_replacements[replacementIdx] = {
                  ...check.question_items[questionIdx].tyre_replacements[replacementIdx],
                  ...update,
                };
              }
            }

            if (check.sublists) {
              const sub = check.sublists[update.group_order + "-" + update.group_name]?.items?.findIndex(i => i.id === question_result_id);
              if (sub > -1) check.sublists[update.group_order + "-" + update.group_name].items[sub] = check.question_items[questionIdx];
            }

            selectedAppointmentChecks[checkIdx] = { ...check };
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.UPDATE_QUESTION_ITEM, selectedAppointmentChecks: [...selectedAppointmentChecks] });
          }
        }

        return; // we only care to update the detail page for this message, not the main page
      } else if (_topic === "TyreReplacementAddedMessage") {
        const { check_id, question_result_id } = body;
        const checkIdx = selectedAppointmentChecks.findIndex(check => check.id === check_id);

        if (checkIdx > -1) {
          const check = selectedAppointmentChecks[checkIdx];
          const questionIdx = check.question_items.findIndex(c => c.id === question_result_id);

          if (questionIdx > -1) {
            if (Array.isArray(check.question_items[questionIdx].tyre_replacements) && check.question_items[questionIdx].tyre_replacements.length) {
              check.question_items[questionIdx].tyre_replacements = [...check.question_items[questionIdx].tyre_replacements, update];
            } else {
              check.question_items[questionIdx].tyre_replacements = [update];
              check.question_items[questionIdx].customer_approved = false;
            }

            if (check.sublists) {
              const sub = check.sublists[update.group_order + "-" + update.group_name]?.items?.findIndex(i => i.id === question_result_id);
              if (sub > -1) check.sublists[update.group_order + "-" + update.group_name].items[sub] = check.question_items[questionIdx];
            }

            selectedAppointmentChecks[checkIdx] = { ...check };
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.UPDATE_QUESTION_ITEM, selectedAppointmentChecks: [...selectedAppointmentChecks] });
          }
        }

        return;
      } else if (_topic === "TyreReplacementRemovedMessage") {
        const { check_id, question_result_id } = body;
        const { tyre_replacement_id } = update;

        const checkIdx = selectedAppointmentChecks.findIndex(check => check.id === check_id);

        if (checkIdx > -1) {
          const check = selectedAppointmentChecks[checkIdx];
          const questionIdx = check.question_items.findIndex(c => c.id === question_result_id);

          if (questionIdx > -1) {
            if (Array.isArray(check.question_items[questionIdx].tyre_replacements)) {
              const repIdx = check.question_items[questionIdx].tyre_replacements.findIndex(r => r.tyre_replacement_id === tyre_replacement_id);
              if (repIdx > -1) {
                if (check.question_items[questionIdx].tyre_replacements[repIdx].customer_answer) check.question_items[questionIdx].customer_approved = false;
                check.question_items[questionIdx].tyre_replacements.splice(repIdx, 1);
              }
            }

            if (check.sublists) {
              const sub = check.sublists[update.group_order + "-" + update.group_name]?.items?.findIndex(i => i.id === question_result_id);
              if (sub > -1) check.sublists[update.group_order + "-" + update.group_name].items[sub] = check.question_items[questionIdx];
            }

            selectedAppointmentChecks[checkIdx] = { ...check };
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.UPDATE_QUESTION_ITEM, selectedAppointmentChecks: [...selectedAppointmentChecks] });
          }
        }

        return; // we only care to update the detail page for this message, not the main page
      } else if (_topic === "QuestionResultUpdateMessage") {
        const { question_result_id } = body;
        const checkIdx = selectedAppointmentChecks.findIndex(check => check.id === update.check_id);

        if (checkIdx > -1) {
          const check = selectedAppointmentChecks[checkIdx];
          const questionIdx = check.question_items.findIndex(c => c.id === question_result_id);

          if (questionIdx > -1) {
            if (update.images) {
              if (!check.question_items[questionIdx].images) check.question_items[questionIdx].images = update.images;
              else {
                update.images.forEach(updateImage => {
                  const idx = check.question_items[questionIdx].images.findIndex(image => image.id === updateImage.id);
                  if (idx !== -1) check.question_items[questionIdx].images[idx] = updateImage;
                  else check.question_items[questionIdx].images.push(updateImage);
                });
              }

              delete update.images;
            } else if (update.videos) {
              if (!check.question_items[questionIdx].videos) check.question_items[questionIdx].videos = update.videos;
              else {
                update.videos.forEach(updateVideo => {
                  const idx = check.question_items[questionIdx].videos.findIndex(video => video.id === updateVideo.id);
                  if (idx !== -1) check.question_items[questionIdx].videos[idx] = updateVideo;
                  else check.question_items[questionIdx].videos.push(updateVideo);
                });
              }

              delete update.videos;
            }

            check.question_items[questionIdx] = {
              ...check.question_items[questionIdx],
              ...update,
            };

            if (check.sublists) {
              const sub = check.sublists[update.group_order + "-" + update.group_name]?.items?.findIndex(i => i.id === question_result_id);
              if (sub > -1) check.sublists[update.group_order + "-" + update.group_name].items[sub] = check.question_items[questionIdx];
            }

            selectedAppointmentChecks[checkIdx] = { ...check };
            dispatch({ type: APPOINTMENTS_ACTION_TYPES.UPDATE_QUESTION_ITEM, selectedAppointmentChecks: [...selectedAppointmentChecks] });
          }
        }

        return; // we only care to update the detail page for this message, not the main page
      } else {
        let car = { ...selectedAppointment.car };

        if (isChecklistUpdated || isFinalCheckDBBUpdated) {
          dispatch(getChecks(selectedAppointment.id));

          try {
            const res = await Service.getCar(selectedAppointment.car.id);
            if (res?.data?.data) car = res.data.data;
          } catch (err) {
            console.log(err);
          }
        }

        const appointment = { ...selectedAppointment, car };

        const show_title_bar_notif = applyUpdateOnAppointments(appointment, updatePayload);

        dispatch({
          type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED,
          subtype: "SELECTED_APPOINTMENT",
          appointment,
          show_title_bar_notif,
        });
      }
    }

    if (skipMainTableUpdate) return;

    // Update the main table
    const selectedDate = moment(getState().appointments.selectedDate);
    const sinceDate = getState().appointments.sinceDate ? moment(getState().appointments.sinceDate) : null;
    for (let i = 0; i < appointments.length; i++) {
      let appointment = appointments[i];
      let appointmentsVisible;

      if (appointments[i].id === body.appointment_id) {
        const old_time_car_app = moment(appointment.time_car_app);
        const dateChanged = !old_time_car_app.isSame(update.time_car_app, "day");
        const show_title_bar_notif = applyUpdateOnAppointments(appointment, updatePayload, user, notificationElements);
        appointments[i] = appointment;
        const time_car_app = moment(appointment.time_car_app);

        if (
          dateChanged &&
          ((!sinceDate && !selectedDate.isSame(time_car_app, "day") && selectedDate.isSame(old_time_car_app, "day")) ||
            (sinceDate && !time_car_app.isBetween(sinceDate, selectedDate, "day", "[]")))
        ) {
          appointments.splice(i, 1);
          appointmentsVisible = appointments;
        } else {
          appointments = sortAppointments(appointments, getState().global.selectedLocation.statuses);
          appointmentsVisible = appointments;
          const FILTERS = getState().appointments.appointmentsFilters;
          if (FILTERS) {
            appointmentsVisible = applyFiltersOnAppointments(appointments, FILTERS);
          }
        }

        dispatch({
          type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED,
          subtype: "ALL_APPOINTMENTS",
          appointments: [...appointments],
          appointmentsVisible,
          show_title_bar_notif,
        });
        return;
      }
    }

    dispatch(noUpdateToApply());
  };
}

export function getAppointment(id) {
  return (dispatch, getState) => {
    return Service.getAppointment(id)
      .then(response => {
        return dispatch(getAppointmentSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(getAppointmentFail(handleErrorMessage(error)));
      });
  };
}

export function getAppointmentByWo(dmsnr, location_id) {
  return (dispatch, getState) => {
    Service.getAppointmentByWo(dmsnr, location_id)
      .then(response => {
        return dispatch(getAppointmentSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(getAppointmentFail(handleErrorMessage(error)));
      });
  };
}

export function getAppointmentSuccess(appointment) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS, appointment });
}

export function getAppointmentFail(errorMessages) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL, errorMessages });
}

export function deselectAppointment() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.DESELECT_APPOINTMENT_SUCCESS });
}

export function deleteAppointmentCheck(checkID) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.DELETE_CHECK, checkID });
}

export function getChecks(appointmentID) {
  return dispatch => {
    Service.getAppointmentChecks(appointmentID)
      .then(response => {
        let checks = response.data.data.checks;
        if (Array.isArray(checks)) {
          checks.forEach(check => {
            if (check.checklist && check.checklist.parent_checklist) {
              check.checklist.parent_checklist.name = check.checklist.name;
              check.checklist = check.checklist.parent_checklist;
            }
          });
        }
        dispatch(getChecksSuccess(checks));
      })
      .catch(error => {
        dispatch(getChecksFail(handleErrorMessage(error)));
      });
  };
}

export function getChecksSuccess(checks) {
  let preparedChecks = checks && checks.length > 0 ? prepareChecks(checks) : [];

  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.GET_CHECKS_SUCCESS, preparedChecks });
}

export function getChecksFail(errorMessages) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.GET_CHECKS_FAIL, errorMessages });
}

export function statusFilterClicked(status) {
  return (dispatch, getState) => {
    let appointmentsFilters = getState().appointments.appointmentsFilters;
    let statuses = getState().global.selectedLocation.statuses_visible;
    let deselectedStatuses = [];
    appointmentsFilters.reset = false;
    if (!statuses || statuses.length < 1) statuses = getState().appointments.statuses;

    for (const s of statuses) {
      if (s.identifier === status.identifier) s.isActive = !s.isActive;

      if (!s.isActive) deselectedStatuses.push(s.identifier);
    }

    appointmentsFilters.deselectedStatuses = deselectedStatuses;
    dispatch({ type: APPOINTMENTS_ACTION_TYPES.STATUS_FILTER_UPDATED, appointmentsFilters, statuses: [...statuses] });
  };
}

export function iconFilterClicked(icon) {
  return (dispatch, getState) => {
    let appointmentsFilters = getState().appointments.appointmentsFilters;

    appointmentsFilters.reset = false;
    if (!appointmentsFilters.selectedIcons.includes(icon)) appointmentsFilters.selectedIcons = appointmentsFilters.selectedIcons.concat(icon);
    else appointmentsFilters.selectedIcons = appointmentsFilters.selectedIcons.filter(di => di !== icon);

    dispatch({ type: APPOINTMENTS_ACTION_TYPES.ICONS_FILTER_UPDATED, appointmentsFilters });
  };
}

export function resetIconFilters() {
  return (dispatch, getState) => {
    let appointmentsFilters = getState().appointments.appointmentsFilters;

    appointmentsFilters.selectedIcons = [];

    dispatch({ type: APPOINTMENTS_ACTION_TYPES.RESET_ICONS_FILTERS, appointmentsFilters });
  };
}

export function resetStatusFilters() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.STATUS_FILTER_RESET });
}

export function appointmentDateTimeClicked(dateTime) {
  localStorage.setItem(APPOINTMENT_DATE_COLUMN, dateTime);

  return dispatch => {
    if (dateTime === "date") {
      dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_DATE_TIME_CHANGE, dateTimeAppointment: "date" });
    } else {
      dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_DATE_TIME_CHANGE, dateTimeAppointment: "time" });
    }
  };
}

export function nameColumnClicked(selectedNameColumn) {
  return dispatch => {
    dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENTS_NAME_COLUMN_SELECTED, selectedNameColumn });
  };
}

export function updatedByColumnClicked(selectedUpdatedByColumn) {
  setPreference(SELECTED_UPDATED_BY_COLUMN_PREFERENCE, selectedUpdatedByColumn);

  return dispatch => {
    dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENTS_UPDATED_BY_COLUMN_SELECTED, selectedUpdatedByColumn });
  };
}

export function carMakesClicked(car) {
  return (dispatch, getState) => {
    let uniqueCarMakes = getState().appointments.uniqueCarMakes;
    let appointmentsFilters = getState().appointments.appointmentsFilters;

    if (car === "shouldCheckFromStorage") {
      let list = getBrandFromStorage();
      appointmentsFilters.carMakes = list;
      list.forEach(l => {
        const i = uniqueCarMakes.findIndex(x => x.name === l);
        if (i > -1) {
          uniqueCarMakes[i].filter = true;
        }
      });
    } else if (car === "showall") {
      uniqueCarMakes.forEach(e => (e.filter = false));
      appointmentsFilters.carMakes = [];
      localStorage.setItem("brand", null);
    } else {
      let ci = uniqueCarMakes.findIndex(i => i.name === car);
      let fci = appointmentsFilters.carMakes.indexOf(car);

      if (ci > -1) {
        uniqueCarMakes[ci].filter = !uniqueCarMakes[ci].filter;
      }

      if (fci > -1) {
        appointmentsFilters.carMakes.splice(fci, 1);
        removeBrandFromStorage(car);
      } else {
        appointmentsFilters.carMakes.push(car);
        addBrandToStorage(car);
      }
    }

    dispatch({ type: APPOINTMENTS_ACTION_TYPES.CAR_MAKES_UPDATED, uniqueCarMakes, appointmentsFilters });
  };
}

export function updateAppointment(requestData) {
  return dispatch => {
    Service.updateAppointment()
      .then(response => {
        console.log("Updated the appointment :::: ", response.data.data);
      })
      .catch(error => {
        console.log("Error updating appointment ::: ", handleErrorMessage(error));
      });
  };
}

export function getSnoozedItems(requestData) {
  return dispatch => {
    Service.getSnoozedItems(requestData)
      .then(response => {
        dispatch(getSnoozedItemsSuccess(response.data.data));
      })
      .catch(error => {
        dispatch(getSnoozedItemsFail(handleErrorMessage(error)));
      });
  };
}

export function getSnoozedItemsSuccess(snoozedItems) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.GET_SNOOZED_ITEMS_SUCCESS, snoozedItems });
}

export function getSnoozedItemsFail(errorMessages) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.GET_SNOOZED_ITEMS_FAIL, errorMessages });
}

export function webSocketConnectionUpdate(webSocketEvent) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_CONNECTION_UPDATE, webSocketEvent });
}

export function webSocketAppointmentsUpdate(webSocketUpdate, webSocketEvent) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_UPDATE, webSocketUpdate, webSocketEvent });
}

export function webSocketAppointmentsRefresh(webSocketUpdate, webSocketEvent) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_REFRESH, webSocketUpdate, webSocketEvent });
}

export function webSocketAppointmentAssignmentUpdated(webSocketEvent) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_ASSIGNMENT_UPDATED, webSocketEvent });
}

export function websocketMechanicAvailabilityUpdated(webSocketEvent) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_MECHANIC_AVAILABILITY_UPDATED, webSocketEvent });
}

export function webSocketDayplannerUpdatesApplied() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.DAYPLANNER_SOCKET_UPDATE_APPLIED });
}

export function updateAppointmentDetailOnlineUsers(users) {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.DETAIL_PAGE_ONLINE_USERS_UPDATE, users: [...users] });
}

export function clearAppointments() {
  return dispatch => dispatch({ type: APPOINTMENTS_ACTION_TYPES.CLEAR_APPOINTMENTS });
}

export function addIntervention(intervention) {
  return (dispatch, getState) => {
    const selectedAppointment = getState().appointments.selectedAppointment;
    let interventions;

    if (!selectedAppointment.interventions) interventions = [intervention];
    else interventions = selectedAppointment.interventions.concat(intervention);

    dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED, subtype: "SELECTED_APPOINTMENT", appointment: { ...selectedAppointment, interventions } });
  };
}

export function deleteIntervention(id) {
  return (dispatch, getState) => {
    const selectedAppointment = getState().appointments.selectedAppointment;
    const interventions = selectedAppointment.interventions.filter(i => i.id !== id);

    dispatch({ type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED, subtype: "SELECTED_APPOINTMENT", appointment: { ...selectedAppointment, interventions } });
  };
}

export function getSnoozeAppointmentHistory(nextPage) {
  return (dispatch, getState) => {
    dispatch({ type: APPOINTMENTS_ACTION_TYPES.STARTS_LOADING_SNOOZE_APPOINTMENT_HISTORY });

    let page = nextPage || getState().appointments.selected_appointment_history_snoozed_questions_page || 1;

    Service.getSnoozeAppointmentHistory({ page, appointment_id: getState().appointments.selectedAppointment.id })
      .then(result => {
        const data = result?.data?.data || { items: [], nb_pages: 0, nb_items: 0 };
        let { items, nb_pages, nb_items } = data;

        if (!items) items = [];

        let grouped = [];

        let interventions = [],
          questions = [],
          interventions_offsets = [],
          questions_offsets = [],
          intervention_offset = 0,
          question_offset = 0;

        items.forEach(i => {
          if (i.intervention_id) {
            if (interventions_offsets[i.intervention_id] === undefined) {
              interventions_offsets[i.intervention_id] = intervention_offset++;
              interventions[interventions_offsets[i.intervention_id]] = { ...i, history: [i] };
            } else {
              if (i.created_on > interventions[interventions_offsets[i.intervention_id]].created_on)
                interventions[interventions_offsets[i.intervention_id]] = {
                  ...i,
                  history: interventions[interventions_offsets[i.intervention_id]].history.concat(i),
                };
              else interventions[interventions_offsets[i.intervention_id]].history.push(i);
            }
          } else if (i.question_result_id) {
            if (questions_offsets[i.question_result_id] === undefined) {
              questions_offsets[i.question_result_id] = question_offset++;
              questions[questions_offsets[i.question_result_id]] = { ...i, history: [i] };
            } else {
              if (i.created_on > questions[questions_offsets[i.question_result_id]].created_on)
                questions[questions_offsets[i.question_result_id]] = { ...i, history: questions[questions_offsets[i.question_result_id]].history.concat(i) };
              else questions[questions_offsets[i.question_result_id]].history.push(i);
            }
          } else {
            console.log("invalid item", i);
          }
        });

        grouped = [...interventions, ...questions];

        grouped.sort((a, b) => (b.appointment.time_car_app > a.appointment.time_car_app ? 1 : -1));

        return dispatch({
          type: APPOINTMENTS_ACTION_TYPES.GET_SNOOZE_APPOINTMENT_HISTORY_SUCCESS,
          selected_appointment_history_snoozed_questions: grouped,
          selected_appointment_history_snoozed_questions_page: page,
          selected_appointment_history_snoozed_questions_nb_pages: nb_pages,
          selected_appointment_history_snoozed_questions_nb_items: nb_items,
          loading_selected_appointment_history_snoozed_questions: false,
        });
      })
      .catch(_error => dispatch({ type: APPOINTMENTS_ACTION_TYPES.GET_SNOOZE_APPOINTMENT_HISTORY_FAIL }));
  };
}

export function handleSnoozeChanged(updatedSnoozeLog) {
  return (dispatch, getState) => {
    let selected_appointment_history_snoozed_questions = getState().appointments.selected_appointment_history_snoozed_questions;

    const updatedItem = updatedSnoozeLog[0];

    selected_appointment_history_snoozed_questions = selected_appointment_history_snoozed_questions.map(i => {
      if ((i.question_result_id && i.question_result_id === updatedItem.question_result_id) || (i.intervention_id && i.intervention_id === updatedItem.intervention_id)) {
        return { ...updatedItem, history: updatedSnoozeLog };
      }

      return i;
    });

    return dispatch({
      type: APPOINTMENTS_ACTION_TYPES.UPDATE_SNOOZE_APPOINTMENT_HISTORY_ITEM,
      selected_appointment_history_snoozed_questions: [...selected_appointment_history_snoozed_questions],
    });
  };
}

export function handleSnoozeDeleted(groupedItem) {
  return (dispatch, getState) => {
    let selected_appointment_history_snoozed_questions = getState().appointments.selected_appointment_history_snoozed_questions;

    selected_appointment_history_snoozed_questions = selected_appointment_history_snoozed_questions.map(i => {
      if ((i.question_result_id && i.question_result_id === groupedItem.question_result_id) || (i.intervention_id && i.intervention_id === groupedItem.intervention_id)) {
        i.history = [];
        i.snooze_status_id = i.snooze_type_id = i.snooze_date = null;
        i.snooze_note = "";
        if (i.question_result) i.question_result.snoozed = false;
        else if (i.intervention) i.intervention.snoozed = false;
      }

      return i;
    });

    return dispatch({
      type: APPOINTMENTS_ACTION_TYPES.DELETE_SNOOZE_APPOINTMENT_HISTORY_ITEM,
      selected_appointment_history_snoozed_questions: [...selected_appointment_history_snoozed_questions],
    });
  };
}

export function updateSnoozeItem(item, update) {
  item.snoozed = true;
  if (!item.snooze_history) item.snooze_history = [update];
  else {
    const snoozeIdx = item.snooze_history.findIndex(p => p.id === update.id);

    if (snoozeIdx > -1) item.snooze_history[snoozeIdx] = { ...item.snooze_history[snoozeIdx], ...update };
    else item.snooze_history = [update, ...item.snooze_history];
  }
}

export function updatePinHistory(item, update) {
  item.pinned = true;
  if (!item.pin_history) item.pin_history = [update];
  else {
    const pinIdx = item.pin_history.findIndex(p => p.id === update.id);

    if (pinIdx > -1) item.pin_history[pinIdx] = { ...item.pin_history[pinIdx], ...update };
    else item.pin_history = [update, ...item.pin_history];
  }
}

export function deletePinHistory(item) {
  item.pinned = false;
  item.pin_history = [];
}

export function deleteSnoozeItem(item) {
  item.snoozed = false;
  item.snooze_history = [];
}

export function updateSnoozedHistory(update) {
  return (dispatch, getState) => {
    let selected_appointment_history_snoozed_questions = getState().appointments.selected_appointment_history_snoozed_questions;
    let selected_appointment_history_pin_questions = getState().appointments.selected_appointment_history_pin_questions;

    const { question_result_id, intervention_id } = update;

    if (question_result_id) {
      const snoozedQuestionsIdx = selected_appointment_history_snoozed_questions.findIndex(q => q.id === question_result_id);
      if (snoozedQuestionsIdx > -1) updateSnoozeItem(selected_appointment_history_snoozed_questions[snoozedQuestionsIdx], update);

      const pinQuestionsIdx = selected_appointment_history_pin_questions.findIndex(q => q.id === question_result_id);
      if (pinQuestionsIdx > -1) {
        updateSnoozeItem(selected_appointment_history_pin_questions[pinQuestionsIdx], update);
        return dispatch({
          type: APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM,
          selected_appointment_history_pin_questions: [...selected_appointment_history_pin_questions],
        });
      }
    } else if (intervention_id) {
      const snoozedQuestionsIdx = selected_appointment_history_snoozed_questions.findIndex(q => q.id === intervention_id);
      if (snoozedQuestionsIdx > -1) updateSnoozeItem(selected_appointment_history_snoozed_questions[snoozedQuestionsIdx], update);

      const pinQuestionsIdx = selected_appointment_history_pin_questions.findIndex(q => q.id === intervention_id);
      if (pinQuestionsIdx > -1) {
        updateSnoozeItem(selected_appointment_history_pin_questions[pinQuestionsIdx], update);
        return dispatch({
          type: APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM,
          selected_appointment_history_pin_questions: [...selected_appointment_history_pin_questions],
        });
      }
    }

    return dispatch({
      type: APPOINTMENTS_ACTION_TYPES.UPDATE_SNOOZE_APPOINTMENT_HISTORY_ITEM,
      selected_appointment_history_snoozed_questions: [...selected_appointment_history_snoozed_questions],
    });
  };
}

export function getAdvisedCriticalHistory(nextPage, hidden_items) {
  return (dispatch, getState) => {
    dispatch({ type: APPOINTMENTS_ACTION_TYPES.STARTS_LOADING_APPOINTMENT_ADVISED_CRITICAL_HISTORY });

    let page = nextPage || getState().appointments.selected_appointment_history_snoozed_questions_page || 1;

    Service.getAdvisedCriticalHistory({ page, appointment_id: getState().appointments.selectedAppointment.id, hidden_items })
      .then(res => {
        let { question_results = [], nb_pages = 0, nb_count = 0 } = res?.data?.data || {};

        if (!question_results) question_results = [];

        question_results.sort((a, b) => (b.time_car_app > a.time_car_app ? 1 : -1));

        return dispatch({
          type: APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_ADVISED_CRITICAL_HISTORY_SUCCESS,
          selected_appointment_history_pin_questions: question_results,
          selected_appointment_history_pin_questions_page: page,
          selected_appointment_history_pin_questions_nb_pages: nb_pages,
          selected_appointment_history_pin_questions_nb_count: nb_count,
        });
      })
      .catch(() => dispatch({ type: APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_ADVISED_CRITICAL_HISTORY_FAIL }));
  };
}

export function handleAnswerUpdateAdvisedCriticalHistory(update) {
  return (dispatch, getState) => {
    const selected_appointment_history_pin_questions = getState().appointments.selected_appointment_history_pin_questions;

    if (update.type === "snooze") {
      selected_appointment_history_pin_questions[update.index].snooze_history = [...update.value];
      selected_appointment_history_pin_questions[update.index].snoozed = update.value?.length > 0;
    } else if (update.type === "pin") {
      selected_appointment_history_pin_questions[update.index].pin_history = [...update.value];
      selected_appointment_history_pin_questions[update.index].pinned = update.value?.length > 0;
    }

    return dispatch({
      type: APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM,
      selected_appointment_history_pin_questions: [...selected_appointment_history_pin_questions],
    });
  };
}

export function handleMediaToggleAdvisedCriticalHistory(questionIndex) {
  return (dispatch, getState) => {
    const selected_appointment_history_pin_questions = [...getState().appointments.selected_appointment_history_pin_questions];
    selected_appointment_history_pin_questions[questionIndex].mediaVisible = !selected_appointment_history_pin_questions[questionIndex].mediaVisible;
    return dispatch({
      type: APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM,
      selected_appointment_history_pin_questions: [...selected_appointment_history_pin_questions],
    });
  };
}

export function updateQuestionHistory(update) {
  return (dispatch, getState) => {
    let selected_appointment_history_pin_questions = getState().appointments.selected_appointment_history_pin_questions;
    const { question_result_id, intervention_id, id } = update;

    if (question_result_id) {
      const questionIdx = selected_appointment_history_pin_questions.findIndex(p => p.id === question_result_id);
      if (questionIdx > -1) updatePinHistory(selected_appointment_history_pin_questions[questionIdx], update);
    } else if (intervention_id) {
      const interventionIdx = selected_appointment_history_pin_questions.findIndex(p => p.id === intervention_id);
      if (interventionIdx > -1) updatePinHistory(selected_appointment_history_pin_questions[interventionIdx], update);
    } else if (id) {
      const index = selected_appointment_history_pin_questions.findIndex(p => p.id === id);
      if (index > -1) selected_appointment_history_pin_questions[index].is_hidden_in_history = update.is_hidden_in_history;
    }

    return dispatch({
      type: APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM,
      selected_appointment_history_pin_questions: [...selected_appointment_history_pin_questions],
    });
  };
}

export function deleteQuestionHistory(update) {
  return (dispatch, getState) => {
    let selected_appointment_history_pin_questions = getState().appointments.selected_appointment_history_pin_questions;
    const { question_result_id, intervention_id } = update;

    if (question_result_id) {
      const questionIdx = selected_appointment_history_pin_questions.findIndex(p => p.id === question_result_id);
      if (questionIdx > -1) deletePinHistory(selected_appointment_history_pin_questions[questionIdx], update);
    } else if (intervention_id) {
      const interventionIdx = selected_appointment_history_pin_questions.findIndex(p => p.id === intervention_id);
      if (interventionIdx > -1) deletePinHistory(selected_appointment_history_pin_questions[interventionIdx], update);
    }

    return dispatch({
      type: APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM,
      selected_appointment_history_pin_questions: [...selected_appointment_history_pin_questions],
    });
  };
}

export function deleteSnoozedHistory(update) {
  return (dispatch, getState) => {
    let selected_appointment_history_snoozed_questions = getState().appointments.selected_appointment_history_snoozed_questions;
    let selected_appointment_history_pin_questions = getState().appointments.selected_appointment_history_pin_questions;
    const { question_result_id, intervention_id } = update;

    if (question_result_id) {
      const questionIdx = selected_appointment_history_snoozed_questions.findIndex(p => p.id === question_result_id);
      if (questionIdx > -1) deleteSnoozeItem(selected_appointment_history_snoozed_questions[questionIdx], update);

      const pinQuestionsIdx = selected_appointment_history_pin_questions.findIndex(q => q.id === question_result_id);
      if (pinQuestionsIdx > -1) {
        deleteSnoozeItem(selected_appointment_history_pin_questions[pinQuestionsIdx], update);
        return dispatch({
          type: APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM,
          selected_appointment_history_pin_questions: [...selected_appointment_history_pin_questions],
        });
      }
    } else if (intervention_id) {
      const interventionIdx = selected_appointment_history_snoozed_questions.findIndex(p => p.id === intervention_id);
      if (interventionIdx > -1) deleteSnoozeItem(selected_appointment_history_snoozed_questions[interventionIdx], update);

      const pinQuestionsIdx = selected_appointment_history_pin_questions.findIndex(q => q.id === intervention_id);
      if (pinQuestionsIdx > -1) {
        deleteSnoozeItem(selected_appointment_history_pin_questions[pinQuestionsIdx], update);
        return dispatch({
          type: APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM,
          selected_appointment_history_pin_questions: [...selected_appointment_history_pin_questions],
        });
      }
    }

    return dispatch({
      type: APPOINTMENTS_ACTION_TYPES.UPDATE_SNOOZE_APPOINTMENT_HISTORY_ITEM,
      selected_appointment_history_snoozed_questions: [...selected_appointment_history_snoozed_questions],
    });
  };
}

export const handleUpdateAppointments = appointment => {
  return (dispatch, getState) => {
    let selectedAppointment = getState().appointments.selectedAppointment;

    if (selectedAppointment) {
      selectedAppointment = { ...appointment };

      return dispatch({
        type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED,
        subtype: "SELECTED_APPOINTMENT",
        appointment: selectedAppointment,
      });
    } else {
      const appointments = [...getState().appointments.appointments];

      const appointmentToUpdateIdx = appointments.findIndex(({ id }) => id === appointment.id);

      if (appointmentToUpdateIdx > -1) {
        appointments[appointmentToUpdateIdx] = appointment;

        return dispatch({
          type: APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED,
          subtype: "ALL_APPOINTMENTS",
          appointmentsVisible: appointments,
          appointments: appointments,
        });
      }
    }

    dispatch(noUpdateToApply());
  };
};

// Needed to obtain appointments for today, ie. list_daily API
let date = moment().hour(12).subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss");
date += "+01:00";

const getInitialState = () => ({
  actionType: "",
  appointments: [],
  appointmentsFilters: {
    selectedIcons: [],
    deselectedStatuses: [],
    carMakes: [],
    searchTerm: "",
    woFilterSelected: WO_FILTERS.ALL,
  },
  appointmentsVisible: [],
  uniqueCarMakes: [],
  errorMessages: {},
  hasError: false,
  isAppointmentDetailVisible: false,
  isLoading: false,
  isLoadingAppointment: false,
  isLoadingAppointments: false,
  isLoadingStatuses: false,
  searchResults: [],
  selectedAppointment: null,
  selectedAppointmentChecks: null,
  selectedDate: date,
  sinceDate: null,
  snoozedItems: [],
  statuses: [],
  subtype: "",
  webSocketUpdate: false,
  webSocketEvent: false,
  dateTimeAppointment: localStorage.getItem(APPOINTMENT_DATE_COLUMN) ? localStorage.getItem(APPOINTMENT_DATE_COLUMN) : "date",
  selectedNameColumn: "",
  selectedUpdatedByColumn: getPreference(SELECTED_UPDATED_BY_COLUMN_PREFERENCE, "updated_by"),
  detailPageOnlineUsers: [],
  show_title_bar_notif: false,
  selected_appointment_history_snoozed_questions: [],
  selected_appointment_history_snoozed_questions_page: 0,
  selected_appointment_history_snoozed_questions_nb_pages: 0,
  selected_appointment_history_snoozed_questions_nb_items: 0,
  loading_selected_appointment_history_snoozed_questions: false,
  selected_appointment_history_pin_questions: [],
  selected_appointment_history_pin_questions_page: 0,
  selected_appointment_history_pin_questions_nb_pages: 0,
  selected_appointment_history_pin_questions_nb_count: 0,
  loading_selected_appointment_history_pin_questions: false,
});

const authReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case APPOINTMENTS_ACTION_TYPES.APPOINTMENTS_LOADING:
      return {
        ...state,
        actionType: action.type,
        isLoadingAppointments: true,
      };

    case APPOINTMENTS_ACTION_TYPES.APPOINTMENT_DATE_TIME_CHANGE:
      return {
        ...state,
        dateTimeAppointment: action.dateTimeAppointment,
      };

    case APPOINTMENTS_ACTION_TYPES.APPOINTMENTS_NAME_COLUMN_SELECTED:
      return {
        ...state,
        actionType: action.type,
        selectedNameColumn: action.selectedNameColumn,
      };

    case APPOINTMENTS_ACTION_TYPES.APPOINTMENTS_UPDATED_BY_COLUMN_SELECTED:
      return {
        ...state,
        actionType: action.type,
        selectedUpdatedByColumn: action.selectedUpdatedByColumn,
      };
    case APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        appointments: action.appointments,
        appointmentsVisible: action.appointmentsVisible,
        uniqueCarMakes: action.uniqueCarMakes,
        appointmentsFilters: {
          ...action.appointmentsFilters,
        },
        selectedDate: action.selectedDate,
        sinceDate: action.sinceDate,
        isLoadingAppointments: false,
      };
    case APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENTS_FAIL:
      return {
        ...state,
        actionType: action.type,
        hasError: true,
        errorMessages: action.errorMessages,
        isLoadingAppointments: false,
      };

    case APPOINTMENTS_ACTION_TYPES.ADD_APPOINTMENT_NOTE:
      return {
        ...state,
        actionType: action.type,
        selectedAppointment: action.selectedAppointment,
      };

    case APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_NOTE:
      return {
        ...state,
        actionType: action.type,
        selectedAppointment: action.selectedAppointment,
      };
    case APPOINTMENTS_ACTION_TYPES.DELETE_APPOINTMENT_NOTE:
      return {
        ...state,
        actionType: action.type,
        selectedAppointment: action.selectedAppointment,
      };
    case APPOINTMENTS_ACTION_TYPES.FILTER_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        appointmentsVisible: action.appointmentsVisible,
        appointmentsFilters: action.appointmentsFilters,
      };
    case APPOINTMENTS_ACTION_TYPES.RESET_APPOINTMENTS_STORE:
      return {
        ...getInitialState(),
      };
    case APPOINTMENTS_ACTION_TYPES.SEARCH_APPOINTMENTS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        searchResults: action.searchResults,
        isLoadingAppointments: false,
      };
    case APPOINTMENTS_ACTION_TYPES.SEARCH_APPOINTMENTS_FAIL:
      return {
        ...state,
        actionType: action.type,
        searchResults: action.searchResults,
        isLoadingAppointments: false,
      };
    case APPOINTMENTS_ACTION_TYPES.APPOINTMENT_LOADING:
      return {
        ...state,
        actionType: action.type,
        isLoadingAppointment: true,
      };
    case APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        selectedAppointment: action.appointment,
        isLoadingAppointment: false,
      };
    case APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_FAIL:
      return {
        ...state,
        actionType: action.type,
        hasError: true,
        errorMessages: action.errorMessages,
        isLoadingAppointment: false,
      };
    case APPOINTMENTS_ACTION_TYPES.GET_CHECKS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        selectedAppointmentChecks: action.preparedChecks,
        isLoadingAppointment: false,
      };

    case APPOINTMENTS_ACTION_TYPES.CLEAR_APPOINTMENTS:
      return {
        ...state,
        actionType: action.type,
        appointments: [],
        appointmentsVisible: [],
      };

    case APPOINTMENTS_ACTION_TYPES.DELETE_CHECK:
      const { selectedAppointmentChecks } = state;
      const { checkID, type } = action;
      const helpArr = selectedAppointmentChecks.filter(check => {
        return check.id !== checkID;
      });
      return {
        ...state,
        actionType: type,
        selectedAppointmentChecks: [...helpArr],
      };

    case APPOINTMENTS_ACTION_TYPES.GET_CHECKS_FAIL:
      return {
        ...state,
        actionType: action.type,
        selectedAppointmentChecks: action.errorMessages ? "error" : [],
        hasError: true,
        errorMessages: action.errorMessages,
        isLoadingAppointment: false,
      };
    case APPOINTMENTS_ACTION_TYPES.STATUS_FILTER_UPDATED:
      return {
        ...state,
        actionType: action.type,
        statuses: action.statuses,
        appointmentsFilters: action.appointmentsFilters,
      };
    case APPOINTMENTS_ACTION_TYPES.ICONS_FILTER_UPDATED:
      return {
        ...state,
        actionType: action.type,
        appointmentsFilters: action.appointmentsFilters,
      };
    case APPOINTMENTS_ACTION_TYPES.RESET_ICONS_FILTERS:
      return {
        ...state,
        actionType: action.type,
        appointmentsFilters: action.appointmentsFilters,
      };
    case APPOINTMENTS_ACTION_TYPES.STATUS_FILTER_RESET:
      let statuses = state.statuses.map(s => {
        s.isActive = true;
        return s;
      });
      return {
        ...state,
        actionType: action.type,
        appointmentsFilters: state.appointmentsFilters,
        statuses,
      };
    case APPOINTMENTS_ACTION_TYPES.CAR_MAKES_UPDATED:
      return {
        ...state,
        actionType: action.type,
        uniqueCarMakes: action.uniqueCarMakes,
        appointmentsFilters: action.appointmentsFilters,
      };
    case APPOINTMENTS_ACTION_TYPES.APPOINTMENT_UPDATES_APPLIED:
      if (action.subtype === "SELECTED_APPOINTMENT") {
        return {
          ...state,
          actionType: action.type,
          selectedAppointment: action.appointment,
          show_title_bar_notif: state.show_title_bar_notif || action.show_title_bar_notif,
        };
      } else {
        return {
          ...state,
          actionType: action.type,
          appointments: action.appointments,
          appointmentsVisible: action.appointmentsVisible,
          show_title_bar_notif: state.show_title_bar_notif || action.show_title_bar_notif,
        };
      }
    case APPOINTMENTS_ACTION_TYPES.HIDE_TITLE_BAR_NOTIF:
      return {
        ...state,
        show_title_bar_notif: false,
      };
    case APPOINTMENTS_ACTION_TYPES.SHOW_TITLE_BAR_NOTIF:
      return {
        ...state,
        show_title_bar_notif: true,
      };
    case APPOINTMENTS_ACTION_TYPES.UPDATE_QUESTION_ITEM:
      return {
        ...state,
        actionType: action.type,
        selectedAppointmentChecks: action.selectedAppointmentChecks,
      };
    case APPOINTMENTS_ACTION_TYPES.NO_UPDATE_TO_APPLY:
      return {
        ...state,
        actionType: action.type,
      };
    case APPOINTMENTS_ACTION_TYPES.DESELECT_APPOINTMENT_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        selectedAppointment: null,
        selectedAppointmentChecks: null,
      };
    case APPOINTMENTS_ACTION_TYPES.GET_SNOOZED_ITEMS_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        snoozedItems: action.snoozedItems,
      };
    case APPOINTMENTS_ACTION_TYPES.GET_SNOOZED_ITEMS_FAIL:
      return {
        ...state,
        actionType: action.type,
        errorMessages: action.errorMessages,
      };
    // case 'persist/REHYDRATE':     // reset the keys that should not be persisted
    // to storage     var incoming = action.payload;     if (incoming &&
    // incoming.appointments) {         return state;     }     return state
    case APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_UPDATE:
    case APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_REFRESH:
      return {
        ...state,
        actionType: action.type,
        webSocketUpdate: action.webSocketUpdate,
        webSocketEvent: action.webSocketEvent,
      };

    case APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_APPOINTMENTS_ASSIGNMENT_UPDATED:
      return {
        ...state,
        actionType: action.type,
        webSocketEvent: action.webSocketEvent,
      };

    case APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_MECHANIC_AVAILABILITY_UPDATED:
      return {
        ...state,
        actionType: action.type,
        webSocketEvent: action.webSocketEvent,
      };

    case APPOINTMENTS_ACTION_TYPES.DAYPLANNER_SOCKET_UPDATE_APPLIED:
      return {
        ...state,
        actionType: action.type,
      };

    case APPOINTMENTS_ACTION_TYPES.WEB_SOCKET_CONNECTION_UPDATE: {
      return {
        ...state,
        actionType: action.type,
        webSocketEvent: action.webSocketEvent,
      };
    }
    case APPOINTMENTS_ACTION_TYPES.DETAIL_PAGE_ONLINE_USERS_UPDATE:
      return {
        ...state,
        detailPageOnlineUsers: action.users,
      };

    case APPOINTMENTS_ACTION_TYPES.STARTS_LOADING_SNOOZE_APPOINTMENT_HISTORY:
      return {
        ...state,
        loading_selected_appointment_history_snoozed_questions: true,
      };

    case APPOINTMENTS_ACTION_TYPES.GET_SNOOZE_APPOINTMENT_HISTORY_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        selected_appointment_history_snoozed_questions: action.selected_appointment_history_snoozed_questions,
        selected_appointment_history_snoozed_questions_page: action.selected_appointment_history_snoozed_questions_page,
        selected_appointment_history_snoozed_questions_nb_pages: action.selected_appointment_history_snoozed_questions_nb_pages,
        selected_appointment_history_snoozed_questions_nb_items: action.selected_appointment_history_snoozed_questions_nb_items,
        loading_selected_appointment_history_snoozed_questions: false,
      };

    case APPOINTMENTS_ACTION_TYPES.GET_SNOOZE_APPOINTMENT_HISTORY_FAIL:
      return {
        ...state,
        selected_appointment_history_snoozed_questions: [],
        loading_selected_appointment_history_snoozed_questions: false,
      };

    case APPOINTMENTS_ACTION_TYPES.UPDATE_SNOOZE_APPOINTMENT_HISTORY_ITEM:
      return {
        ...state,
        actionType: action.type,
        selected_appointment_history_snoozed_questions: action.selected_appointment_history_snoozed_questions,
      };

    case APPOINTMENTS_ACTION_TYPES.DELETE_SNOOZE_APPOINTMENT_HISTORY_ITEM:
      return {
        ...state,
        actionType: action.type,
        selected_appointment_history_snoozed_questions: action.selected_appointment_history_snoozed_questions,
      };

    case APPOINTMENTS_ACTION_TYPES.STARTS_LOADING_APPOINTMENT_ADVISED_CRITICAL_HISTORY:
      return {
        ...state,
        loading_selected_appointment_history_pin_questions: true,
      };

    case APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_ADVISED_CRITICAL_HISTORY_SUCCESS:
      return {
        ...state,
        actionType: action.type,
        selected_appointment_history_pin_questions: action.selected_appointment_history_pin_questions,
        selected_appointment_history_pin_questions_page: action.selected_appointment_history_pin_questions_page,
        selected_appointment_history_pin_questions_nb_pages: action.selected_appointment_history_pin_questions_nb_pages,
        selected_appointment_history_pin_questions_nb_count: action.selected_appointment_history_pin_questions_nb_count,
        loading_selected_appointment_history_pin_questions: false,
      };

    case APPOINTMENTS_ACTION_TYPES.UPDATE_APPOINTMENT_ADVISED_CRITICAL_HISTORY_ITEM:
      return {
        ...state,
        actionType: action.type,
        selected_appointment_history_pin_questions: action.selected_appointment_history_pin_questions,
      };

    case APPOINTMENTS_ACTION_TYPES.GET_APPOINTMENT_ADVISED_CRITICAL_HISTORY_FAIL:
      return {
        ...state,
        selected_appointment_history_pin_questions: [],
        loading_selected_appointment_history_pin_questions: false,
      };

    case APPOINTMENTS_ACTION_TYPES.WO_FILTER_SELECTED:
      return {
        ...state,
        actionType: action.type,
        appointmentsFilters: { ...state.appointmentsFilters, woFilterSelected: action.woFilterSelected },
      };

    default:
      return state;
  }
};

export default authReducer;
